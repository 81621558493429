<template>
  <header id="site-header">
    <figure class="logo">
      <a href="./">
        <img src="https://banzaijapan.love/lib/img/logo.png" alt="BANZAI JAPAN" />
      </a>
    </figure>
    <div class="trg" @click="trg = !trg" :class="{'active': trg}">
      <i></i>
      <i></i>
      <i></i>
    </div>
    <Transition name="nav">
      <div class="fix" v-if="trg">
        <nav class="gnavi" role="navigation" itemscope="itemscope" itemtype="http://scheme.org/SiteNavigationElement">
          <figure class="logo">
            <a href="./">
              <img src="https://banzaijapan.love/lib/img/logo_white.png" alt="BANZAI JAPAN" />
            </a>
          </figure>
          <ul>
            <li itemprop="name"><a itemprop="url" href="news/">NEWS</a></li>
            <li itemprop="name"><a itemprop="url" href="about/">BANZAI JAPAN</a></li>
            <li itemprop="name"><a itemprop="url" href="members/">MEMBERS</a></li>
            <li itemprop="name"><a itemprop="url" href="schedule/">SCHEDULE</a></li>
            <li itemprop="name"><a itemprop="url" href="blog/">BLOG</a></li>
            <li><a href="https://www.youtube.com/playlist?list=PLi8gmrSnYhoYQfKSMMFLikahFCLS5g8rt" target="_blank">MOVIE<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960"><path d="M224.62-160q-27.62 0-46.12-18.5Q160-197 160-224.62v-510.76q0-27.62 18.5-46.12Q197-800 224.62-800h224.61v40H224.62q-9.24 0-16.93 7.69-7.69 7.69-7.69 16.93v510.76q0 9.24 7.69 16.93 7.69 7.69 16.93 7.69h510.76q9.24 0 16.93-7.69 7.69-7.69 7.69-16.93v-224.61h40v224.61q0 27.62-18.5 46.12Q763-160 735.38-160H224.62Zm164.92-201.23-28.31-28.31L731.69-760H560v-40h240v240h-40v-171.69L389.54-361.23Z"/></svg></a></li>
            <li itemprop="name"><a itemprop="url" href="report/">REPORT</a></li>
            <li itemprop="name"><a itemprop="url" href="idol-entry.html">AUDITION</a></li>
            <li itemprop="name"><a itemprop="url" href="contact/">CONTACT</a></li>
          </ul>
          <div class="sns" data-eng="＼ Follow Me ／">
            <a class="ln" href="http://line.me/R/msg/text/?%E6%97%A5%E6%9C%AC%E3%82%92%E5%85%83%E6%B0%97%E3%81%AB%E4%B8%96%E7%95%8C%E3%81%AB%E7%99%BA%E4%BF%A1%EF%BC%8147%E9%83%BD%E9%81%93%E5%BA%9C%E7%9C%8C%E5%A4%A9%E4%B8%8B%E7%B5%B1%E4%B8%80%E3%82%A2%E3%82%A4%E3%83%89%E3%83%AB%E3%80%8CBANZAI%20JAPAN%E3%80%8Dhttps%3A%2F%2Fbanzaijapan.love" target="_blank" rel="nofollow">
              <svg xmlns="http://www.w3.org/2000/svg"  viewBox="0 0 48 48"><path d="M25.12,44.521c-2.114,1.162-2.024-0.549-1.933-1.076c0.054-0.314,0.3-1.787,0.3-1.787c0.07-0.534,0.144-1.36-0.067-1.887 c-0.235-0.58-1.166-0.882-1.85-1.029C11.48,37.415,4.011,30.4,4.011,22.025c0-9.342,9.42-16.943,20.995-16.943S46,12.683,46,22.025 C46,32.517,34.872,39.159,25.12,44.521z M18.369,25.845c0-0.56-0.459-1.015-1.023-1.015h-2.856v-6.678 c0-0.56-0.459-1.015-1.023-1.015c-0.565,0-1.023,0.455-1.023,1.015v7.694c0,0.561,0.459,1.016,1.023,1.016h3.879 C17.91,26.863,18.369,26.406,18.369,25.845z M21.357,18.152c0-0.56-0.459-1.015-1.023-1.015c-0.565,0-1.023,0.455-1.023,1.015 v7.694c0,0.561,0.459,1.016,1.023,1.016c0.565,0,1.023-0.456,1.023-1.016V18.152z M30.697,18.152c0-0.56-0.459-1.015-1.023-1.015 c-0.565,0-1.025,0.455-1.025,1.015v4.761l-3.978-5.369c-0.192-0.254-0.499-0.406-0.818-0.406c-0.11,0-0.219,0.016-0.325,0.052 c-0.419,0.139-0.7,0.526-0.7,0.963v7.694c0,0.561,0.46,1.016,1.025,1.016c0.566,0,1.025-0.456,1.025-1.016v-4.759l3.976,5.369 c0.192,0.254,0.498,0.406,0.818,0.406c0.109,0,0.219-0.018,0.325-0.053c0.42-0.137,0.7-0.524,0.7-0.963V18.152z M36.975,20.984 h-2.856v-1.817h2.856c0.566,0,1.025-0.455,1.025-1.015c0-0.56-0.46-1.015-1.025-1.015h-3.879c-0.565,0-1.023,0.455-1.023,1.015 c0,0.001,0,0.001,0,0.003v3.842v0.001c0,0,0,0,0,0.001v3.845c0,0.561,0.46,1.016,1.023,1.016h3.879 c0.565,0,1.025-0.456,1.025-1.016c0-0.56-0.46-1.015-1.025-1.015h-2.856v-1.817h2.856c0.566,0,1.025-0.455,1.025-1.015 c0-0.561-0.46-1.016-1.025-1.016V20.984z"/></svg>
            </a>
            <a class="fb" href="https://www.facebook.com/sharer/sharer.php?u=https://banzaijapan.love" target="_blank" rel="nofollow">
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M16.403,9H14V7c0-1.032,0.084-1.682,1.563-1.682h0.868c0.552,0,1-0.448,1-1V3.064c0-0.523-0.401-0.97-0.923-1.005C15.904,2.018,15.299,1.999,14.693,2C11.98,2,10,3.657,10,6.699V9H8c-0.552,0-1,0.448-1,1v2c0,0.552,0.448,1,1,1l2-0.001V21c0,0.552,0.448,1,1,1h2c0.552,0,1-0.448,1-1v-8.003l2.174-0.001c0.508,0,0.935-0.381,0.993-0.886l0.229-1.996C17.465,9.521,17.001,9,16.403,9z"/></svg>
            </a>
            <a class="x" href="https://twitter.com/share?url=https://banzaijapan.love&text=%e6%97%a5%e6%9c%ac%e3%82%92%e5%85%83%e6%b0%97%e3%81%ab%e4%b8%96%e7%95%8c%e3%81%ab%e7%99%ba%e4%bf%a1%ef%bc%8147%e9%83%bd%e9%81%93%e5%ba%9c%e7%9c%8c%e5%a4%a9%e4%b8%8b%e7%b5%b1%e4%b8%80%e3%82%a2%e3%82%a4%e3%83%89%e3%83%ab%e3%80%8cBANZAI%20JAPAN%e3%80%8d" target="_blank" rel="nofollow">
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 50 50"><path d="M 6.9199219 6 L 21.136719 26.726562 L 6.2285156 44 L 9.40625 44 L 22.544922 28.777344 L 32.986328 44 L 43 44 L 28.123047 22.3125 L 42.203125 6 L 39.027344 6 L 26.716797 20.261719 L 16.933594 6 L 6.9199219 6 z"/></svg>
            </a>
          </div>
        </nav>
      </div>
    </Transition>
  </header>
</template>

<style lang="scss" scoped>
.nav-enter-active,
.nav-leave-active {
  transition: opacity 0.5s $ease;
}
.nav-enter-from,
.nav-leave-to {
  opacity: 0;
}
header{
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  padding: 1.2rem side();
  display: grid;
  grid-template-columns: auto auto;
  z-index: 1000;
  @media screen and (max-width: $phone){
    background: $white;
    padding: 1.1rem side();
  }
  .logo{
    width: 14rem;
    justify-self: flex-start;
    @media screen and (max-width: $phone){
      width: 13rem;
    }
    img{
      width: 100%;
    }
  }
  .trg{
    position: fixed;
    top: 0;
    right: 0;
    width: 6.4rem;
    height: 6.4rem;
    cursor: pointer;
    border-radius: 0 0 0 1rem;
    overflow: hidden;
    z-index: 10;
    @media screen and (max-width: $phone){
      width: var(--head);
      height: var(--head);
    }
    &:before,
    &:after{
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: $base;
      z-index: -1;
    }
    &:after{
      width: 0;
      left: auto;
      right: 0;
      background: darken($normal, 20%);
      transition: all 320ms $ease;
    }
    &:hover{
      &:after{
        width: 100%;
        left: 0;
        right: auto;
      }
    }
    &.active{
      &:after{
        width: 100%;
      }
      &:hover{
        &:after{
          background: $normal;
        }
      }
      i{
        width: 0;
        &:first-of-type,
        &:last-of-type{
          width: 40%;
          top: 50%;
        }
        &:first-of-type{
          transform: translate(-50%) rotate(45deg);
        }
        &:last-of-type{
          transform: translate(-50%) rotate(-45deg);
        }
      }
    }
    i{
      width: 33%;
      height: 1px;
      background: $white;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%);
      transition: all 320ms $ease;
      &:first-of-type{
        top: calc(50% - .5rem);
      }
      &:last-of-type{
        top: calc(50% + .5rem);
      }
    }
  }
  .fix{
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background: #000;
  }
  nav{
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-color: $base;
    background-image: url(../../images/common/nav_tex_2x.webp);
    background-size: 200px;
    background-repeat: repeat;
    z-index: 9;
    @include flex();
    align-items: center;
    justify-content: center;
    animation: bgRepeat 4000ms linear infinite;
    @keyframes bgRepeat {
      0%{
        background-position: 0 0;
      }
      100%{
        background-position: -200px -100px;
      }
    }
    .logo{
      position: absolute;
      top: 1.2rem;
      left: side();
      z-index: 10;
    }
    ul{
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      grid-column-gap: 2.4vmin;
      grid-row-gap: 2.4vmin;
      @media screen and (max-width: $tab){
        grid-template-columns: repeat(1, 1fr);
      }
      li{
        line-height: 1;
        a{
          font-size: 7.2vmin;
          color: $white;
          fill: $white;
          display: inline-flex;
          align-items: center;
          position: relative;
          padding: .4rem;
          z-index: 1;
          @media screen and (max-width: $tab){
            font-size: 6.4vmin;
          }
          &:before{
            content: '';
            width: 0;
            height: 100%;
            background: $sub;
            position: absolute;
            top: 50%;
            right: 0;
            transform: translateY(-50%);
            transition: width 320ms $ease;
            z-index: -1;
          }
          &:hover{
            &:before{
              width: 100%;
              left: 0;
              right: auto;
            }
          }
          svg{
            width: 7.2vmin;
            height: 7.2vmin;
            display: inline-block;
            margin: 0 0 0 .8vmin;
          }
        }
      }
    }
    .sns{
      position: absolute;
      right: 0;
      bottom: 0;
      z-index: 2;
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      &:before{
        content: attr(data-eng);
        color: $white;
        position: absolute;
        top: -1rem;
        left: 50%;
        transform: translate(-50%, -100%);
        white-space: nowrap;
        animation: jumpText 800ms $ease infinite;
        @keyframes jumpText {
          0%,100%{
            transform: translate(-50%, -100%);
          }
          60%{
            transform: translate(-50%, -120%);
          }
        }
      }
      a{
        width: 4rem;
        height: 4rem;
        background: $sub;
        border-right: darken($sub, 8%) 1px solid;
        @include flex();
        align-items: center;
        justify-content: center;
        &:hover{
          background: darken($sub, 8%);
        }
        &:last-of-type{
          border: none;
        }
        svg{
          width: 1.4rem;
          height: 1.4rem;
          fill: $white;
        }
        &.ln{
          svg{
            width: 1.72rem;
            height: 1.72rem;
          }
        }
      }
    }
  }
}
</style>

<script>
export default {
  data: function(){
    return {
      trg: false
    }
  }
}
</script>
