import gsap from 'gsap'
import { TweenMax, Power2 } from 'gsap'
import { ScrollTrigger } from "gsap/ScrollTrigger"
import * as THREE from 'three'

gsap.registerPlugin(ScrollTrigger)

export default function index(){
	gsap.fromTo('.main .movetitle',
		{},
		{
			y: 100,
			scrollTrigger: {
				trigger: '.site-idol-entry .first',
				start: "top bottom",
				end: "top top",  
				scrub: 0,
				once: false,
				invalidateOnRefresh: true
			}
		}
	)

  document.querySelector('.main__bg__pic .zoomout').classList.add('active')

	const container = document.getElementById('particles')

	const scene = new THREE.Scene()
	const camera = new THREE.PerspectiveCamera(75, container.clientWidth / container.clientHeight, 0.1, 1000)
	const renderer = new THREE.WebGLRenderer({ alpha: true }) 
	renderer.setSize(container.clientWidth, container.clientHeight)
	container.appendChild(renderer.domElement)

	const geometry = new THREE.PlaneGeometry(1, 1)
	const confettiCount = 32
	const confettis = []

	function getRandomColor() {
		const colors = [0xc8141e, 0xc88f14]
		return colors[Math.floor(Math.random() * colors.length)]
	}

	for (let i = 0; i < confettiCount; i++) {
		const material = new THREE.MeshBasicMaterial({ color: getRandomColor(), side: THREE.DoubleSide })
		const confetti = new THREE.Mesh(geometry, material)

		// X軸は広げつつ、Z軸の範囲を狭める
		const xPosition = (Math.random() - 0.5) * 100  // X軸範囲 (-50 ~ 50)
		const yPosition = Math.random() * 50 + 20      // Y軸範囲
		const zPosition = (Math.random() - 0.5) * 20   // Z軸範囲を狭める (-10 ~ 10)

		confetti.position.set(xPosition, yPosition, zPosition)
		confetti.rotation.set(Math.random() * Math.PI, Math.random() * Math.PI, Math.random() * Math.PI)
		confetti.scale.set(0.5, 0.5, 0.5)
		
		confetti.userData = {
			speed: Math.random() * 0.1 + 0.05,
			rotationSpeedX: Math.random() * 0.02 - 0.01,
			rotationSpeedY: Math.random() * 0.02 - 0.01
		}

		scene.add(confetti)
		confettis.push(confetti)
	}

	camera.position.z = 30

	function animate() {
		requestAnimationFrame(animate)

		confettis.forEach(confetti => {
			confetti.position.y -= confetti.userData.speed
			confetti.rotation.x += confetti.userData.rotationSpeedX
			confetti.rotation.y += confetti.userData.rotationSpeedY

			if (confetti.position.y < -20) {
				confetti.position.y = Math.random() * 50 + 20
				confetti.position.x = (Math.random() - 0.5) * 100
				confetti.position.z = (Math.random() - 0.5) * 20  // Z軸範囲を狭める
			}
		})

		renderer.render(scene, camera)
	}

	animate()

	window.addEventListener('resize', () => {
		camera.aspect = window.innerWidth / window.innerHeight
		camera.updateProjectionMatrix()
		renderer.setSize(container.clientWidth, container.clientHeight)
	})
}
